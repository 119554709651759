@media only screen and (max-width: 575.98px) {

  .m-large-button {
    width: 180px;
    height: 61px;
  }

  .m-fill {
    width: 100% !important;
  }

  .shipment-list-table-mobile-only .list-row .col-3 {
    width: 12%;
    align-self: center;
  }

  .next-button.sa-btn {
    width: 50%;
  }


  .form-footer.footer-sm .sa-btn {
    width: 50%;
    min-width: 62px !important;
  }


  .tracking-text {
    padding: 0 18px 0 12px;
  }


  .login-page .form-footer {
    position: fixed;
    bottom: 5px;
    left: 0;
    right: 0;
    background: #FFF;
    margin: 0;
    z-index: 999;
    /* border: 1px solid rgba(50, 50, 50, 0.1); */
    padding: 5px 10px;
  }

  .f-p-btn {
    width: 100%;
  }

  .rate_price {
    display: inline-block;
    float: left;
    width: 25%;
  }

  .mz div.mat-grid-tile-content {
    /*justify-content: left !important;*/
  }

}
