/* You can add global styles to this file, and also import other style files */
/*@import '@angular/material/prebuilt-themes/indigo-pink.css';*/

@import "mobile-styles.css";

.firebase-emulator-warning {
  display: none !important;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.margin-right2 {
  margin-right: 2%;
}

.white-background {
  background-color: #FAFAFA !important;
}

.float-right {
  float: right;
}

.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.min-height-100vh {
  min-height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.mat-menu-panel {
  max-width: 100% !important;
}

.custom-menu + * .cdk-overlay-pane > div.mat-menu-panel > div.mat-menu-content {
  width: 260px;
  max-width: 300px !important;
  padding: 25px;
}

.cdk-overlay-pane > div.mat-menu-panel {
  margin-top: 6px !important;
}

/* .cdk-overlay-pane {
  top: 200px;
  left: 152px;
  bottom: 40px;
} */

.load-more {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}


.page-wrap {
  display: flex;
  /* background: #FAFAFA */
}

.page-wrap .sidebar-wrapper {
  width: 290px;
  /* height: 100vh; */
  background: #FAFAFA;
  border-right: 1px solid #EDEDED;
}

.page-wrap .sidebar-wrapper .sidebar-logo {
  padding: 35px 30px 0 20px;
}

.page-wrap .sidebar-wrapper ul {
  list-style-type: none;
  padding: 0 5px;
  margin-top: 100px;
}

.page-wrap .sidebar-wrapper ul li {
  margin: 10px;
}

.page-wrap .sidebar-wrapper ul li a {
  padding: 10px 12px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  display: flex;
  align-items: center;
}

.page-wrap .sidebar-wrapper ul li a:hover {
  background: rgba(48, 79, 254, 0.05);
  color: #304FFE;
  cursor: pointer;
}

.page-wrap .sidebar-wrapper ul li a {
  text-decoration: none;
}

.page-wrap .sidebar-wrapper ul li a.active {
  background: rgba(48, 79, 254, 0.05);
  color: #304FFE;
  align-items: center;
}

.page-wrap .sidebar-wrapper ul li a .icon {
  margin-right: 10px;
}

.page-wrap .sidebar-content-wrap {
  width: calc(100vw - 0px);
}

.page-wrap .sidebar-content-wrap .sidebar-content-nav {
  padding: 21px 56px;
  background: #FAFAFA;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page-wrap .sidebar-content-wrap .sidebar-content-nav button.create-label {
  height: auto;
  padding: 14px 16px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #FFFFFF;
  position: relative;
  margin-left: auto;
  right: 88px;
}

.page-wrap .sidebar-content-wrap .sidebar-content-inner-wrap {
  padding: 20px;
}

.sidebar-logo img {
  height: 23px;
  width: 120px;
}

.mobile-menu-wrap a .link-text {
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: rgba(50, 50, 50, 0.5);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.mobile-menu-wrap a.active .link-text {
  color: #323232;
}

.mobile-menu-wrap a .icon {
  color: rgba(50, 50, 50, 0.5);
}

.mobile-menu-wrap a.active .icon {
  color: #304FFE;
}


.cdk-overlay-pane {
  display: block !important;
}

.tooltip {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  margin-left: 15px !important;
}

.tooltip-light .tooltip-arrow {
  display: none !important;
}

.tooltip .rate-warning {
  padding: 30px 15px !important;
  margin: 0 !important;
  border-radius: 10px !important;
}

.tooltip .rate-error {
  padding: 30px 15px !important;
  margin: 0 !important;
  border-radius: 10px !important;
}

.shipping-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.shipping-form-wrapper {
  max-width: 935px;
  margin: 0 auto;
  box-shadow: none !important;
  background: none !important;
}

.shipping-form-wrapper .shipping-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #282828;
  margin: 20px !important;
}

.shipping-form-wrapper .right.toggle-switch {
  text-align: right;
}

.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline .mat-form-field-outline-start,
.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-color: #79747E !important;
}

.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-start,
.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-start,
.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-end {
  border-color: #f44336 !important;
}

/* .shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-bottom-color: #79747E
} */

/* .shipping-form-wrapper .form-content-wrapper .shipping-detail .insurance-wrapper .mat-form-field-appearance-outline .mat-form-field-outline {
  border-color:  rgba(0, 0, 0, 0.12) !important;
} */

.shipping-form-wrapper .form-content-wrapper .shipping-detail .insurance-wrapper .mat-form-field-appearance-outline .mat-form-field-outline-start,
.shipping-form-wrapper .form-content-wrapper .shipping-detail .insurance-wrapper .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

/* .shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-form-field-has-label .mat-form-field-outline .mat-form-field-outline-gap {
  border-color: #79747E !important;
} */

.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #79747E !important;
}

.shipping-form-wrapper .form-content-wrapper .shipping-detail .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336 !important;
}

.shipping-detail {
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 22px 26px;
  margin-bottom: 15px;
}

.shipping-form-wrapper .shipping-detail {
  /* margin-bottom: 30px; */
  border: none;
  border-radius: 10px;
}

/*.shipping-form-wrapper .shipping-detail.package-box {*/
/*  margin-bottom: 0px;*/
/*}*/

.shipping-form-wrapper .shipping-detail .field-wrapper .mat-button-toggle-button {
  padding: 0 16px;
}

.shipping-form-wrapper .shipping-detail .field-wrapper .mat-button-toggle-button {
  line-height: 36px;
}

.shipping-form-wrapper .form-content-wrapper hr {
  border: 1px solid #EDEDED;
  max-width: 100%;
  margin: 0;
}

.shipping-detail p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #282828;
}


.shipping-form-wrapper .insurance-detail {
  /* margin-bottom: 30px; */
  border: none;
  border-radius: 10px;
}

.insurance-detail {
  /* border: 1px solid #EDEDED; */
  box-sizing: border-box;
  border-radius: 10px;
  padding: 22px 26px;
  margin-bottom: 15px;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 80px;
}

.form-footer a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #304FFE;
  padding: 39px 16px;
}

.next-button {
  background: rgba(48, 79, 254, 0.05) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px !important;
  letter-spacing: 1.25px;
  font-weight: 700;
}


.items-box {
  max-width: 753px;
  height: 170px;
  background: rgba(229, 229, 229, 0.11);
  border: 2px dashed #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
}

.items-box p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: #323232;
  margin: 50px 0px;
  text-align: center;
}

/*.field-wrapper .mat-form-field-infix {*/
/*  width: auto !important;*/
/*}*/

.sa-stroked-btn {
  background: rgba(48, 79, 254, 0.05) !important;
}

.clearBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px !important;
}

.clearBtn a {
  text-decoration: none;
}

.footer-sm {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/*.field-wrapper .mat-form-field-appearance-outline .mat-form-field-infix {*/
/*  padding: 1.3em 0 1.1em 0 !important;*/
/*  border: none !important;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.package-box {
  /* max-width: 753px; */
  /* height: 136px; */
  background: rgba(25, 66, 210, 0.05);
  /* border: 2px dashed #EDEDED; */
  box-sizing: border-box;
  border-radius: 10px;
}

.package-box span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1942D2;
}

.packge-x {
  font-weight: 400 !important;
  font-size: 16px !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
}

/* Search */
.search-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.search-xs-form mat-form-field,
.search-xs-form .date-range-picker {
  margin-left: 20px;
  margin-right: 20px;
}

.search-button {
  margin-top: 1.5em;
}

.date-range-picker {
  padding-top: 0.85em;
}

.cell-content-size-2 {
  font-size: 15px;
  font-weight: 100;
  padding: 1px;
}

.cell-content-size-3 {
  font-size: 12px;
  font-weight: 100;
  padding: 1px;
  padding-left: 5px;
}

.cell-content-size-4 {
  font-size: 10px;
  font-weight: 100;
  padding: 1px;
}

.example-section {
  display: flex;
  align-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.example-margin {
  margin: 10px;
}

.d-action-btn{
  background: rgba(48, 79, 254, 0.05) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px !important;
  letter-spacing: 1.25px;
  font-weight: 700;
  color: #304ffe !important;
}

.sa-btn {
  /*width: 200px;*/
  min-width: 200px !important;
  height: 61px !important;
}


.headerPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.headerPanel p {
  margin: 0;
}

.headerPanel .mat-icon {
  cursor: pointer;
}

.btn-border-radius {
  border: 1px solid #C4C4C4 !important;
  border-radius: 100px !important;
  padding: 7px 15px 7px 10px !important;
  /* margin: 0px 23px; */
}

.footerPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 128px
}

.margin-left {
  margin-left: 20px;
}

.filter-container {
  display: flex;
  align-items: center;
  /* margin-left: 123px */
}

.example-radio-group {
  /* display: flex; */
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

.example-radio-button {
  margin: 15px;
}

.radio-icon-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-wrapper {
  margin-bottom: 45px;
}

.slider-wrapper mat-slider {
  margin: 10px 0 0 0;
  padding: 8px 0 0 0;
}

.slider-wrapper .slider-lables {
  display: flex;
  justify-content: space-between;
}

.slider-wrapper .slider-lables p {
  margin: 0;
  font-size: 14px;
}

.sort-by-filter-wrapper .arrow-wrapper mat-icon {
  color: #C4C4C4;
}

.sort-by-filter-wrapper .arrow-wrapper mat-icon.active:first-child {
  color: #979797;
}

.sort-by-filter-wrapper .arrow-wrapper mat-icon.active:last-child {
  color: #979797;
}

.chip-selected {
  background: rgba(25, 66, 210, 0.1) !important;
  color: #1942D2 !important;
}

.rate-detail {
  box-sizing: border-box;
  padding: 22px 0px;
}

.rate-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border: 1px solid #1942D2;
  box-sizing: border-box;
  border-radius: 10px;
}

.rate-price {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  letter-spacing: 0.15px;
  color: black;
  margin-bottom: 11px;
}

.amount-span {
  color: #1942D2;
  font-weight: 200;
  margin-right: 5px;
}

.rate-span-day {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #323232;
  margin-bottom: 3px;
}

.rate-span-carrier {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #323232;
}

.rate-section {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 33px;
}

.rates-wrapper {
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 10px;
}

.rates-wrapper-active {
  border: 1px solid #1942D2;
  box-sizing: border-box;
  border-radius: 10px;
}

.form-icon-color {
  color: #79747E;
}

.pac-item {
  line-height: 40px !important;
}

.pac-icon {
  margin-top: 10px !important
}

.pac-container {
  background: #FAFAFA !important;
  border: 0.5px solid #D8D8D8 !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  z-index: auto;
  /* top: 408px !important; */
}

.pac-container-with-sign {
  top: 439px !important;
}

.pac-container-without-sign {
  top: 342px !important;
}

/* dashbord css start */

.chart-toggle-filter {
  background: #FAFAFA;
  border-radius: 10px !important;
  border: 1px solid #C4C4C4 !important;
}

.heading-p {
  height: 24px;
  left: 134px;
  top: 37px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #79747E;
}

/* pulse effect start */

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 0px;
  height: 10px;
  width: 10px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.form-content-wrapper {
  /* display: flex; */
  position: relative;

}

.label-step {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #EDEDED;
  /* flex: 1 0 auto; */
  margin: -30px 0 0 -40px;
  position: absolute;
  top: 0;
  right: -110px;

}

.label-step span.active {
  font-size: 144px;
}

svg > g > rect:first-child {
  fill: #FAFAFA;
}

/* Start Login css  */
.login-page {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.login-card-header {
  align-items: center;
  padding: 20px 1px 0px 1px;
  display: flex;
  justify-content: center;
}

.login-card-header h1 {
  text-align: center;
}

/* .login-card-header img {
  width: 60px;
  height: 60px;
  padding-left: 140px;
  padding-right: 100px;
  padding-bottom: 10px;
} */

.login-card-details {
  display: flex;
  flex-direction: column;
}

.login-card-details mat-form-field {
  padding: 5px 1px 5px 1px;
}

.login-card-footer {
  display: flex;
  flex-direction: column;
  padding: 30px 1px 20px 1px;
  text-align: center;
}

.login-card-footer p a {
  padding: 10px;
}

.but-signin {
  height: 60px;
}

.login-card-details .mat-form-field-outline {
  height: 60px;
}

.login-card-details .mat-form-field-infix {
  border: none;
  padding-top: 26px;
}

.f-p-img {
  width: 37.71px !important;
  height: 36px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;

}


.login-card {
  text-align: center;
  width: 708px;
  height: 100%;
  background: #FFFFFF;
  border-radius: 50px 0px 0px 0px;
  padding-bottom: 25px;
}

.f-p-h1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.15px;
  color: #061551;
}

.fg-p-h1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.15px;
  color: #79747E;
}

.f-p-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #FFFFFF;
  width: 346px;
  height: 59px;
  background: rgba(25, 66, 210, 0.9);
  border-radius: 4px;
  gap: 10px
}

.login-span {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #79747E;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.login-span-margin {
  margin: 36px 130px
}

.font-bold {
  font-weight: bold;
}

/* End css */
.mat-button-toggle-checked {
  background-color: #e0e0e0 !important;
}

.passwordless {
  color: #79747E
}

a {
  text-decoration: none !important;
}

.list-padding {
  padding: 40px
}

.stripe-card-number {
  /* width: 341px !important;
  background-color: red !important; */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: 1.25px;
  color: #79747E;
}

.stripe-expiry-date,
.stripe-cvc {
  width: 231px;
}


.stripe-elem {
  border: 1px solid #979797;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px;
  margin-bottom: 15px;
}

.btn-stripe-payment {
  height: 59px !important;
  background: rgba(25, 66, 210, 0.9) !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #FFFFFF !important;
  width: 100%;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}

.tracking-history-status {
  text-align: center;
  margin-bottom: 51px;
}

.tracking-history-wrapper {
  margin: 0px 84px 40px 84px;
}

.field-wrapper.padding {
  padding: 15px;
}

/* .sm-w-100 .mat-grid-tile {
  position: relative !important;
} */

.mat-snack-bar-handset .mat-snack-bar-container {
  width: auto !important;
}

.rate_price {
  display: inline-block;
  float: left;
  width: 25%;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 767.98px) {
  .login-card {
    width: 100%;
  }

  .shipment-list-table-mobile-only .tag.label {
    line-height: 32px;
    height: 32px;
    margin-bottom: 0;
    margin-right: 0;
    text-align: center;
    max-width: 120px;
  }

  .shipment-list-table-mobile-only .tag.label.PACKED {
    width: auto;
  }

  .shipment-list-table-mobile-only .column-data h4 {
    margin: 0 0 10px;
  }

  .shipment-list-table-mobile-only .shippment-data-wrapper {
    margin: 10px;
  }

  .shipment-list-table-mobile-only .column-data h4 {
    word-break: break-all;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #323232;
  }

  .shipment-list-table-mobile-only .column-data small {
    word-break: break-all;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #323232;
    margin: 0 0 10px;
  }

  .shipment-list-table-mobile-only .column-data span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #323232;
    margin: 0 0 10px;
  }

  .shipment-list-table-mobile-only .column-data a span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #1942D2 !important;
  }

  .shipment-list-table-mobile-only .shippment-data-wrapper {
    max-width: 45%;
    min-width: 25%;
  }
}

.form-content-wrapper .apply-button .mat-progress-spinner circle,
.form-content-wrapper .apply-button .mat-spinner circle {
  stroke: #ffffff !important;
}

@media only screen and (max-width: 575.98px) {

  .dashboard-page-wrapper mat-card-content.mat-card-content.dashboard-card-content {
    margin-bottom: 20px !important;
  }

  .field-wrapper.padding {
    padding: 15px !important;
  }

  .modal-button .cancel {
    margin-right: 20px;
  }

  .modal-button .apply-button {
    padding: 21px 46px !important;
    width: 155px !important;
  }

  .custom-recipient-modal .mat-card.shipping-form-wrapper .form-content-wrapper {
    margin-top: 0px !important;
  }

  /*.input-wrap .mat-hint {*/
  /*  display: none;*/
  /*}*/

  .email-dialog {
    padding: 10px !important
  }

  .sm-display {
    display: block !important;
  }

  .sm-display .sm-field {
    width: 100% !important;
  }

  .shipment-header-detail .status-icon {
    height: 100% !important;
  }

  .contact-info .content {
    width: 100% !important;
    word-break: break-word !important;
    padding: 13px;
    padding-right: 13px !important;
  }

  .grid-container .clearBtn {
    justify-content: right;
    height: 20px !important;
  }

  .grid-container .refresh-btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: #304FFE;
    align-items: center;
    justify-content: right;
    display: flex !important;
  }

  .shipment-detail-header {
    background: white !important
  }

  .app-container,
  .page-wrap {
    background-color: #FFFFFF !important;
  }

  .login-page {
    margin-top: 0;
  }

  .login-card form,
  .login-card button,
  .login-card .mat-form-field {
    width: 100% !important;
  }

  .login-span-margin,
  .footer-label {
    margin: 0 !important;
  }

  .login-span-margin a {
    color: #1942D2;
  }

  .label-step,
  .shipping-form-wrapper .shipping-title {
    display: none;
  }

  .shipping-form-header {
    margin-bottom: 15px;
  }

  .mat-card.shipping-form-wrapper {
    padding: 0;
  }

  .mat-card.shipping-form-wrapper .profile-icon {
    margin-left: 20px;
    display: inline-block;
  }

  .mat-card.shipping-form-wrapper .profile-icon .mat-icon {
    font-size: 60px;
    height: 55px;
    width: 55px;
  }

  .mat-card.shipping-form-wrapper .profile-icon span {
    color: #304FFE;
    font-weight: 500;
    font-size: 20px;
    display: inline-block;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper {
    /* border: 1px solid #EFEFEF; */
    border-radius: 10px;
    margin-top: 40px;
    /* padding: 22px 12px; */
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper ul li {
    margin-bottom: 40px;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper ul li:last-child {
    margin-bottom: 0px;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #323232;
    font-size: 20px;
    width: 100%;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper ul li a .link-inner-wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper ul li a .link-inner-wrap .mat-icon {
    margin-right: 20px;
  }

  .mat-card.shipping-form-wrapper .form-content-wrapper {
    padding-bottom: 50px;
    max-width: 100%;
  }

  .input-wrap.field {
    display: inline-flex;
    font-size: 14px;
  }

  .contact-info.custom-row.pick-shipment {
    padding: 22px 13px !important;
  }

  .shipping-detail {
    padding: 20px 16px !important;
  }

  .mobile-pl-16 {
    padding-left: 16px;
  }

  .insurance-detail {
    padding: 5px;
  }

  .contact-info.custom-row.pick-shipment .left .input-wrap {
    width: 25% !important;
  }

  h3.pick-shipment-heading {
    display: none !important;
  }

  .exp-panel-heading {
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
  }

  .contact-info.custom-row.pick-shipment > div .right {
    width: 88% !important;
  }

  .contact-info.custom-row.pick-shipment > div .left .x-mark {
    margin: 0 10px !important;
  }

  .package-box {
    width: 100% !important;
  }

  .shipping-detail .field-wrapper {
    max-width: 100% !important;
  }

  .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .shipping-detail .dimension-package {
    flex-wrap: wrap;
  }

  .shipping-detail .dimension-package .mat-form-field {
    max-width: 25% !important;
    flex: 1 1 25% !important;
  }

  .shipping-detail .dimension-package .mat-form-field {
    margin-left: 0 !important;
  }

  .shipping-form-wrapper .form-content-wrapper .form-footer {
    position: fixed;
    bottom: 72px;
    left: 0;
    right: 0;
    background: #FFF;
    margin: 0;
    z-index: 999;
    /*border: 1px solid rgba(50, 50, 50, 0.1);*/
    padding: 5px 10px;
  }

  .shipping-form-wrapper .form-content-wrapper .footer-signin {
    bottom: 0;
  }

  .shipping-form-wrapper .form-footer {
    position: fixed;
    bottom: 72px;
    left: 0;
    right: 0;
    background: #FFF;
    margin: 0;
    z-index: 999;
    /*border: 1px solid rgba(50, 50, 50, 0.1);*/
    padding: 5px 10px;
  }

  .shipment-detail-box .form-footer {
    position: fixed;
    bottom: 72px;
    left: 0;
    right: 0;
    background: #FFF;
    margin: 0;
    z-index: 999;
    /*border: 1px solid rgba(50, 50, 50, 0.1);*/
    padding: 15px 10px 5px 10px;
  }

  .payment-method-wrapper .form-footer {
    position: fixed;
    bottom: 72px;
    left: 0;
    right: 0;
    /*background: #FFF;*/
    margin: 0;
    z-index: 999;
    /*border: 1px solid rgba(50, 50, 50, 0.1);*/
    padding: 5px 10px;
  }


  .user-list-wrapper .form-footer {
    display: flex !important;
    position: fixed;
    bottom: 72px;
    left: 0;
    right: 0;
    /*background: #FFF;*/
    margin: 0;
    z-index: 999;
    /*border: 1px solid rgba(50, 50, 50, 0.1);*/
    padding: 5px 10px;
  }

  .container-extra {
    background: transparent !important;
    padding-top: 0 !important;
  }

  .container-extra .card-padding {
    padding: 0;
  }

  .page-wrap .sidebar-content-wrap .sidebar-content-inner-wrap {
    padding: 10px 10px 150px 10px !important;
    margin-bottom: 20px;
  }

  .mt-2 {
    margin-top: 10px;
  }

  .hidden-sm {
    display: none !important;
  }


  .pdf-span {
    margin-left: 0 !important;
  }

  .insurance-wrapper {
    max-width: 100% !important;
  }

  .insurance-wrapper .mat-form-field {
    flex: 1 1 100% !important;
    max-width: 100% !important;
    margin: .75em 0 !important
  }

  .insurance-wrapper .mat-form-field-subscript-wrapper {
    position: relative;
  }

  .pac-container {
    /* top: 365px !important; */
    background: white !important;
  }

  .email-container-width {
    width: 100% !important;
  }

  .capture-padding {
    padding: 0 !important;
  }

  .congratulation-text-align {
    text-align: center !important;
  }

  .shipment-header-detail {
    padding: 24px 6px !important;
  }

  .shipment-header-detail .toolbar-inner {
    width: 100%;
  }

  .shipment-header-detail .days-count {
    width: initial !important;
  }

  .shipment-header-detail p.status-icon-p {
    /* font-size: 13px !important; */
    padding: 6px !important;
  }

  .shipment-header-detail p.shipment-id {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .shipment-detail-box {
    padding: 10px 6px !important;
  }

  .shipment-detail-box .mat-expansion-panel-header {
    padding: 0 18px 0 12px;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row {
    padding: 0 40px;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .tracking-info-row {
    padding: 20px 0px 30px 0;
    flex-wrap: wrap;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .tracking-info-row .info {
    margin-left: 8px;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .tracking-info-row .d-flex {
    width: 100%;
    margin-bottom: 10px;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .tracking-info-row .d-flex h3 {
    font-size: 20px;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .tracking-info-row .d-flex .mat-headline {
    line-height: 16px;
    font-size: 14px;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .tracking-info-row .track-id-wrap {
    width: 100%;
    margin-bottom: 0;
    justify-content: center;
  }

  .contact-info-wrpper.track-shipment-wrapper .tracking-info.custom-row .rate-detail {
    padding: 0px;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info {
    padding: 0px 36px !important;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info .contact-info-inner {
    width: 100%;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row.pick-shipment > div {
    flex-wrap: wrap;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row.pick-shipment > div .left,
  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row.pick-shipment > div .right {
    width: 100%;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row.pick-shipment > div .right {
    margin-top: 12px;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row .shipment-option-form .left,
  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row .shipment-option-form .right {
    width: 100%;
    margin: 0 0 10px 0;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row .shipment-option-form {
    flex-wrap: wrap;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row .shipment-option-form .left .mat-form-field-subscript-wrapper {
    top: calc(100% - 3px) !important;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row .book-shipment-row {
    padding: 24px 10px !important;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row div .left,
  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row div .right {
    width: 100%;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row div {
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .shipment-detail-box .contact-info-wrpper .contact-info.custom-row .book-shipment-row .right .content-p {
    text-align: center;
    margin: 8px 0 0 0 !important;
  }

  .contact-info-wrpper.book-shipment-wrapper .contact-info.custom-row.header-row > div {
    padding: 0 !important;
    flex-wrap: inherit !important;
  }

  .list-padding {
    padding: 10px 0;
  }

  .data-table-responsive .data-table-header-responsive,
  .data-table-responsive .data-table-body-responsive {
    display: none !important;
  }

  .account-page-title h3.shipping-title {
    display: block !important;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;

  }

  .edit-people-wrapper .edit-form-header {
    padding: 0px 10px !important;
    justify-content: flex-end;
    margin-left: 0 !important;
  }

  .edit-people-wrapper .edit-form-header a.breadcrumb-people {
    display: none;
  }

  .edit-people-wrapper .edit-user-wrapper {
    padding: 0 10px !important;
    flex-wrap: wrap;

  }

  .edit-people-wrapper .edit-user-wrapper h3.email-title {
    font-size: 16px !important;
  }

  .edit-people-wrapper .edit-user-wrapper .status-info {
    margin-left: 8px !important;
    font-size: 13px;
    width: auto;
    padding: 0 8px;
  }

  .edit-people-wrapper .edit-user-wrapper .edit-user-detail {
    width: 100%;
    justify-content: space-between;

  }

  .edit-people-wrapper .edit-user-wrapper .tenant-id-detail {
    margin: 0;
    justify-content: flex-end;
    width: 100%;
  }

  .add-people-form .mat-dialog-content {
    width: 100% !important;
    overflow: inherit;
  }

  .add-people-form .mat-dialog-content.role-info {
    width: 100% !important;
  }

  .add-people-form .role-info {
    width: 100% !important;
    height: initial !important;
  }

  .mobile-menu-wrap {
    display: flex !important;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
    background-color: #fff;
    padding: 12px 0;
    height: 72px;
    box-sizing: border-box;
    border-top: 1px solid rgba(50, 50, 50, 0.1);
  }

  .mobile-menu-wrap ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .mobile-menu-wrap > ul > li {
    width: 33.33%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu-wrap > ul > .mobile-sidebar-setting-menu {
    width: 33.33%;
    box-shadow: none;
    position: relative;
    overflow: inherit;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-content {
    position: absolute;
    top: -110px;
    z-index: 999;
    right: 50px;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-content .mat-expansion-panel-body li a {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-header {
    padding: 0;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-header .mat-expansion-indicator {
    display: none;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-header .mat-content {
    display: block;
    text-align: center;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-header .mat-content .mat-icon {
    margin: 0 !important;
  }

  .mobile-menu-wrap ul .mobile-sidebar-setting-menu .mat-expansion-panel-header .mat-content p {
    width: 100%;
    margin: 0;
  }

  .create-label-floating-button {
    position: fixed;
    right: 30px;
    bottom: 90px;
    z-index: 999;
  }

  .create-label-floating-button button {
    background: #304FFE;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%), 0px 2px 4px rgb(0 0 0 / 20%);
    border-radius: 50px;
    border: none;
    width: 55px;
    height: 55px;
    color: #ffffff;
  }

  .mobile-only-label {
    display: flex !important;
    align-items: center;
    margin: 25px 35px;
  }

  .mobile-only-label .sidebar-logo img {
    width: auto;
    height: 29px;
  }

  .mobile-only-label .label {
    display: flex;
    align-items: center
  }

  .mobile-only-label .label button {
    margin-left: 8px;
    padding-right: 8px;
  }

  .field-wrapper .mat-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-content {
    font-size: 16px;
  }

  .mat-dialog-container {
    margin: 24px 0;
    height: 93% !important;
  }

  .payment-method-wrapper h3 {
    font-size: 26px;
  }

  .payment-method-wrapper .center {
    width: 100%;
    padding: 30px;
    display: initial !important;
  }

  .payment-method-wrapper .center > div {
    margin: 0 8px !important;
    text-align: center;
  }

  .filter-container {
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .shipment-list-wrapper .primary-btn .mat-button-wrapper .mat-icon {
    margin-left: 5px;
  }

  .shipment-list-table-mobile-only {
    display: block !important;
  }

  .shipment-list-table-mobile-only .status-header {
    display: flex;
    margin-bottom: 20px;
  }

  .shipment-list-table-mobile-only .status-header .s-date,
  .shipment-list-table-mobile-only .status-header .s-status {
    font-weight: 500;
    padding: 12px 16px;
    margin-left: 12px;
  }

  .shipment-list-table-mobile-only .list-row {
    display: flex;
    width: 100%;
    /*align-items: flex-end;*/
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px;
    padding-bottom: 10px; */
    border: 1px solid rgba(229, 229, 229, 0.38);
  }

  .shipment-list-wrapper .shipment-list-table-mobile-only .list-row,
  .user-list-table-wrapper .user-list-wrapper .shipment-list-table-mobile-only .list-row {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-bottom: 10px;
    padding-bottom: 10px; */
    border: 1px solid rgba(229, 229, 229, 0.38);
  }

  .shipment-list-table-mobile-only .list-row p.tag {
    height: 32px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    font-size: 14px;
    margin: 0 8px 30px 0;
    padding: 0 8px;
  }

  .shipment-list-table-mobile-only .list-row .empty-tag {
    height: 32px;
  }

  .shipment-list-table-mobile-only .list-row .column-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 10px;
  }

  .shipment-list-table-mobile-only .list-row .col-1 {
    flex: 1;
    margin: 0 15px;
  }

  .shipment-list-table-mobile-only .list-row .col-2 {
    width: 50%;
    text-align: center;
  }


  .shipment-list-table-mobile-only .list-row .company-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shipment-list-table-mobile-only .list-row .shipment-detail-info {
    margin: 0 25px;
  }

  .shipment-list-table-mobile-only .list-row .shipment-detail-info h4 {
    margin: 0 0 10px 0;
  }

  .shipment-list-table-mobile-only .list-row .more-action {
    display: flex;
    align-items: center;
    /*margin: 0 0 30px;*/
  }

  .shipment-list-table-mobile-only .list-row .more-action button {
    width: 30px;
  }

  .shipment-list-table-mobile-only .list-row .more-action a {
    margin: 0 8px;
  }

  .page-wrap .sidebar-content-wrap .sidebar-content-nav {
    display: none;
  }

  .loading-list-wrap {
    min-height: 140px !important;
  }

  .user-list-loader {
    min-height: 140px !important;
  }

  .print-label-wrapper .print-label,
  .print-label-wrapper .tracking-number-wrap,
  .print-label-wrapper .download-btn {
    width: 100% !important;
  }

  .print-label-wrapper {
    flex-wrap: wrap;
  }

  .print-label-wrapper .download-btn {
    display: none !important;
  }

  .print-label-wrapper .tracking-number-wrap {
    margin-top: 12px !important;
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .print-label-wrapper .tracking-number-wrap .tracking-no-text {
    display: block !important;
    margin-bottom: 0;
  }

  .print-label-wrapper .tracking-number-wrap .mat-icon {
    display: none;
  }

  .user-list-wrapper .search-form {
    display: none !important;
  }

  .next-button {
    max-width: 50%;
  }

  .account-page-title .form-header {
    display: flex !important;
    justify-content: space-between;
  }

  .user-list-wrapper .form-header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .payment-method-wrapper .form-header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  /* .shippment-data-wrapper .column-data h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75%;
  } */
  .tracking-history-wrapper {
    margin: 0;
  }

  .tracking-info {
    padding: 0 24px !important;
    margin: 0 !important;
  }

  .track-shipment-wrapper .tracking-info:first-of-type .tracking-text,
  .labelStatus {
    display: none;
  }

  .tracking-info-row {
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
  }

  .tracking-info-row .d-flex,
  .track-id-wrap {
    width: 100% !important;
  }

  .print-btn {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: #FFFFFF;
    /*background: rgba(25, 66, 210, 0.9);*/
    /*border: 1px solid rgba(0, 0, 0, 0.12);*/
    width: 180px;
    height: 61px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .shipment-detail-box .form-footer {
    display: flex !important;
  }

  .payment-method-wrapper .form-footer {
    display: flex !important;
  }

  .payment-method-wrapper .user-list-wrapper .btn-add {
    display: none;
  }

  .shipping-form-wrapper .shipping-title-sm {
    display: block !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #282828;
    padding: 0 12px;
  }

  .shipping-form-wrapper {
    padding: 0 12px;
  }

  .shipping-form-wrapper .rates-wrapper .mat-grid-list .mat-grid-tile {
    padding-left: 10px;
  }

  .shipping-form-wrapper .right.toggle-switch {
    /* padding: 0 12px; */
  }

  .shipping-form-wrapper .form-content-wrapper .rate-detail {
    padding: 0;
    margin-bottom: 15px;
  }

  .shipping-form-wrapper .form-content-wrapper .rate-detail .field-wrapper > div {
    margin-right: 8px !important;
  }

  .shipment-option-insurance {
    display: flex;
  }

  .shipment-option-insurance .left-control {
    width: 100% !important;
  }

  .shipment-option-insurance .left-control .mat-form-field {
    width: 100% !important;
  }

  .shipment-option-insurance .right-insurance-text {
    margin-left: 14px;
    width: 100% !important;
  }

  .shipping-detail .tracking-info .history-row {
    margin: 0 0 30px 0 !important;
  }

  .login-page .login-card form .mat-form-field-type-mat-input {
    /*margin-bottom: 15px;*/
  }

  .refresh-btn-wrap .refresh-btn {
    background: transparent !important;
    border: none !important;
    width: inherit !important;
    margin-right: 20px;
  }

  .shipping-form-wrapper .shipping-detail .field-wrapper .mat-button-toggle-button {
    padding: 0 6px;
  }

  .shipping-form-wrapper .shipping-detail .field-wrapper .mat-button-toggle-button span {
    letter-spacing: 0.2px;
  }

  .edit-people-wrapper .edit-form-header a.back-btn,
  .edit-people-wrapper .edit-form-header a.close-btn {
    display: block !important;
  }

  .shipment-action-wrap {
    display: none !important;
  }

  .account-main-form-width {
    max-width: 100% !important;
  }

  .custom-modalbox .mat-dialog-container {
    width: 100% !important;
  }

  .confirmation-popup .mat-dialog-content {
    padding: 12px 22px !important;
  }

  .confirmation-popup .mat-dialog-actions {
    padding: 0px 22px !important;
  }

  .confirmation-popup .mat-dialog-actions .btn-alert-dialog {
    width: 120px;
  }

  mat-card.mat-card.user-list-wrapper {
    padding: 10px !important;
  }

  .shipment-detail-header .header-bred {
    padding: 0 !important
  }
}

@media only screen and (min-width: 767.98px) {
  .shipping-form-wrapper.book-shipment-wrap {
    max-width: 70%;
    margin-left: 8%;
  }

}


.shipment-detail-header .header-bred {
  padding: 0 73px
}

/******** End media queries ********/

.shipment-detail-box .mat-expansion-panel-content .mat-action-row {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.refresh-btn-wrap {
  display: flex;
  justify-content: flex-end;
}

.refresh-btn-wrap .refresh-btn {
  display: flex;
  align-items: center;
  background: rgba(48, 79, 254, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #304FFE;
  width: 200px;
  padding: 12px 8px;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.shipment-dimention-wrap {
  display: flex;
  align-items: center;
}

.shipment-option-insurance .left-control {
  width: 100%;
}

.shipment-option-insurance .right-insurance-text {
  width: 40%;
}

.shipping-title-sm {
  display: none;
}

.user-list-wrapper .form-footer {
  display: none;
}

.payment-method-wrapper .form-footer {
  display: none;
}


.shipment-detail-box .form-footer {
  display: none;
}

.shipment-detail-header {
  background: #FAFAFA
}

.payment-method-wrapper .form-header {
  display: none;
}

.user-list-wrapper .form-header {
  display: none;
}

.account-page-title .form-header {
  display: none;
}

.breadcrumb-people {
  display: flex;
  align-items: center;
}

.tenant-id-detail {
  display: flex;
  align-items: center;
  margin-left: 30%;
}

.email-container-width {
  width: 20vw;
}

.card-padding {
  padding: 40px
}

.capture-padding {
  display: block;
  padding: 0 75px;
}

.shipment-header-detail {
  padding: 45px 194px 0 129px;
  justify-content: space-between;
}

.shipment-detail-box {
  padding: 45px 194px 0 129px
}

.edit-people-wrapper .edit-user-wrapper .edit-user-detail {
  display: flex;
  align-items: center;
}

.mobile-menu-wrap {
  display: none;
}

.mobile-sidebar-menu {
  box-shadow: none;
  background: #fafafa;
  margin-top: 13px;
}

.create-label-floating-button,
.mobile-only-label,
.shipment-list-table-mobile-only {
  display: none;
}

.cdk-overlay-pane.my-dialog {
  position: relative !important;
}

.close.mat-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.loading-list-wrap {
  padding: 24px;
  position: relative;
  border-radius: 10px;
  min-height: 350px;
  align-items: center;
  display: flex;
}

.mat-dialog-container .loading-list-wrap {
  border: 1px solid #EDEDED;
  margin-bottom: 30px;
}

.loading-list-wrap .mat-spinner circle {
  stroke: #2e57f9 !important;
}

.loading-list-wrap .loading-list-inner {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.loading-list-wrap .loading-list-inner p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #79747E;
}

.loading-list-wrap .loading-list-inner .mat-progress-spinner {
  margin: 30px auto 0;
}

.search-form {
  justify-content: space-between;
  align-items: center;
}

.heading-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #323232;
}

.mat-button, .mat-flat-button {
  font-weight: 700;
  font-size: 14px !important;
  letter-spacing: 1.25px;
  text-transform: capitalize;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.5) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.5) !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 4px !important;
}


.tracking-no-text {
  text-align: center;
}

.track-id-wrap {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100% !important;
}


.shipping-form-wrapper.account-page-title {
  max-width: 1326px !important;
  margin: 0 auto;
  box-shadow: none !important;
  background: none !important;
}

.shipping-form-wrapper.account-page-title .account-detail {
  max-width: 935px !important;
  margin: 0 auto;
}

.shipping-form-wrapper.account-page-title .form-content-wrapper {
  max-width: 935px !important;
  margin: 0 auto;
}

.account-page-title .shipping-detail {
  /* border: 1px solid #EDEDED; */
  margin-bottom: 25px;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.book-btn .mat-spinner circle {
  stroke: white !important;
}

.user-list-loader {
  padding: 24px;
  position: relative;
  border-radius: 10px;
  min-height: 265px;
  align-items: center;
  display: flex;
}

.user-list-loader p.cell-content-subheader {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 16px;
  line-height: 24px;
  color: #79747E;
}

.user-list-loader .user-list-loader-inner {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.user-list-loader .user-list-loader-inner p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #79747E;
}

.user-list-loader .user-list-loader-inner .mat-progress-spinner {
  margin: 30px auto 0;
}

/* .shipping-form-wrapper .form-footer{
  margin-top: 30px;
} */

.edit-people-wrapper .edit-form-header a.back-btn,
.edit-people-wrapper .edit-form-header a.close-btn {
  display: none;
}

.edit-people-wrapper .edit-form-header {
  margin-left: 16px;
}

.mat-dialog-container .modal-title {
  padding: 16px 32px;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.mat-dialog-container .modal-title h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.custom-modalbox .mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  padding: 20px 32px;
  overflow: hidden;
  width: 685px;
}


.custom-recipient-modal .mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.recipient-modal-form {
  border: none !important;
  padding: 0 72px;
  margin-bottom: 15px !important;
}

.apply-button {
  width: 259px;
  height: 59px;
  justify-content: center;
  align-items: center;
  height: 59px;
  background: rgba(25, 66, 210, 0.9) !important;
  line-height: 16px !important;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #FFFFFF !important;
}

.modal-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin: 0 0 0 95px; */
}

/* .custom-modalbox . */


.account-main-form-width {
  max-width: 80%;
}

.confirmation-popup .mat-dialog-title {
  padding: 8px 8px 8px 32px;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-popup .mat-dialog-title h1 {
  font-size: 16px;
  margin: 0;
}

.confirmation-popup .mat-dialog-content {
  padding: 22px 32px;
  margin: 0;
}

.confirmation-popup .mat-dialog-actions {
  padding: 0px 32px;
  margin: 0px 0 12px 0;
}

.confirmation-popup .mat-dialog-actions .cancel-btn {
  color: #304FFE;
  background-color: transparent;
  border: none;
}

.confirmation-popup .mat-dialog-actions .delete-btn {
  color: #F42323;
  background-color: rgba(244, 35, 35, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.confirmation-popup .mat-dialog-container .modal-content .modal-footer button {
  margin-left: 20px;
}

.shipment-detail-box mat-icon.mat-icon.notranslate.check_circle_outline.material-icons-outlined.material-icons.mat-icon-no-color {
  position: relative;
}

.shipment-detail-box mat-icon.filled-icon:before {
  content: '';
  position: absolute;
  /*background: rgb(25 66 210 / 49%);*/
  border-radius: 50%;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 3px;
}


.shipment-detail-box mat-icon.filled-icon-red:before {
  content: '';
  position: absolute;
  /*background: rgb(248 185 186 / 49%);*/
  border-radius: 50%;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 3px;
}

/* .shipment-detail-box .mat-expansion-panel-content .mat-action-row button.mat-button-base {
  margin-left: auto;
} */

.bordered {
  border: 1px solid #EDEDED !important;
  border-radius: 10px !important;
  margin-bottom: 0 !important;
}

.dashboard-page-wrapper,
.dashboard-page-wrapper .card-padding {
  padding-top: 0 !important;
}

.dashboard-page-wrapper .card-padding .refresh-btn-wrap .refresh-btn {
  background: transparent;
  border: none;
  width: 160px;
  padding: 12px 0px;
}

.seprator {
  border-bottom: 1px solid #EDEDED !important;
  border-radius: 0 !important;
}

.shipping-detail.seprator {
  padding-bottom: 40px;
  /* margin-bottom: 40px; */
}

.insurance-detail.seprator {
  padding: 40px 26px;
}

.package-box-wrapper.seprator {
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.exp-panel-heading {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
}

.shipment-list-wrapper .primary-btn {
  padding: 6px 8px 6px 6px;
  font-size: 13px;
}

/* .mat-form-field.mat-focused .mat-form-field-label {
  color: rgb(223, 213, 73)!important;
} */

.input-wrap.field {
  display: inline-flex;
  font-size: 16px;
}

.insurance-detail {
  padding: 5px !important;
}

.pac-container {
  z-index: 10000 !important;
}

/*
.mat-form-field-outline:not( .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick){
  color: red !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  border: none !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 1;
}

.mat-form-field-outline-start {
  border-top: 1px solid #79747e !important;
  border-left: 1px solid #79747e !important;
  border-bottom: 1px solid #79747e !important;
}

.mat-form-field-outline-gap {
  border-bottom: 1px solid #79747e !important;
}

.mat-form-field-outline-end {
  border-top: 1px solid #79747e !important;
  border-right: 1px solid #79747e !important;
  border-bottom: 1px solid #79747e !important;
}

.mat-form-field-flex:hover .mat-form-field-outline-gap {
  border-top: 1px solid #79747e !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: transparent !important;
} */

.dashboard-spinner-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  flex-basis: auto !important;
}
